import toast from 'react-hot-toast';
import {useState, useCallback, useEffect, React} from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import FileInput from '../components/file-input';
import {callAPI} from '../libs/api-helper';

const _ = () => {
  const navigate = useNavigate();
  const {cardId} = useParams();
  const [card, setCard] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const [title, setTitle] = useState("");
  const [arType, setARType] = useState("");
  const [targetImage, setTargetImage] = useState(null);
  const [questions, setQuestions] = useState([]);


  const refetchCard = useCallback(async() => {
    const result = await callAPI('assets/card', {id: cardId});
    const card = result.item;
    console.log('card', card);
    setCard(card);
    setTitle(card.title || "");
    setARType(card.arType);
    setTargetImage(card.targetImage);

    if (card.questions) {
      setQuestions(card.questions);
    }
  }, [setCard, cardId, setARType, setTitle, questions, setQuestions]);

  const saveHandler = useCallback(async () => {
    const updateParams = {id: cardId};

    updateParams.title = title;
    updateParams.arType = arType;
    updateParams.targetImage = targetImage;
    updateParams.questions = [...questions];

    setSubmitting(true);
    console.log("update card", updateParams);
    const updateResult = await callAPI("assets/update-card", updateParams);

    console.log("upload resut", updateResult);
    setSubmitting(false);
    toast.success('update successful');
  }, [questions, cardId, title, targetImage, arType, setSubmitting]);

  const backHandler = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    refetchCard();
  }, []);
 
  return (
    <div className="card-page">
      <div className="common-header">
        <div>
          <div className="link" onClick={backHandler}>Back</div>
        </div>
      </div>

      <div className="main">
        <table border="1">
          <tbody>
            <tr>
              <td>Set</td>
              <td>{card && card.set.title}</td>
            </tr>
            <tr>
              <td>Title</td>
              <td>
                <input type="text" value={title} onChange={(e) => setTitle(e.target.value)}/>
              </td>
            </tr>
    	    <tr>
    	      <td>Quiz</td>
    	      <td>
              
              {questions.map((q, index) => {
                return <>
                <p>Question {index}  </p>
              <p className='text-and-button'>
                <input type="text" value={q.question} onChange={(e) => { 
                  q.question = e.target.value
                  setQuestions([...questions])
                 }}/>
                 <button onClick={() => {
                    
                    setQuestions([...questions.filter((a, i) => i !==index)])

              }}>Remove</button>
              </p>
              <p>Answers (1st one is correct)</p> 
              {q.answers.map((ans, ansIndex) => (
                <p key={ansIndex} className='text-and-button'>
                  <input type="text" value={ans} onChange={(e) => {
                    q.answers[ansIndex] = e.target.value;
                    setQuestions([...questions])

                  }}/>
                  <button onClick={() => {
                    q.answers = q.answers.filter((a, i) => i !==ansIndex)
                    setQuestions([...questions])

              }}>Remove</button>
                </p>
              ))}
              <p><button onClick={() => {
                    q.answers.push("")
                    setQuestions([...questions])

              }}>Add Answer</button></p>
                </>
              })}
              <hr />
                <p><button onClick={() => {
                    questions.push({
                      question: `question - ${questions.length}`,
                      answers: ['answer1','answer2','answer3','answer4','answer5',]
                    })
                    setQuestions([...questions])

              }}>Add Question</button></p>
              
            </td>
          </tr>
            <tr>
              <td>Card Image</td>

              <td>
                {targetImage && (
                  <div>
                    <img alt="target" width="200px" src={targetImage.path} />

		    <div>
		      <button onClick={() => {setTargetImage(null)}}>Clear</button>
		    </div>
                  </div>
                )}

                {!targetImage && card && (
                  <div>
                    <FileInput 
		      allowMultiple={false}
                      format={['png', 'jpg', 'jpeg']}
		      prefix={"sets/" + card.setId + "/cards/" + cardId}
		      generateUniqueFilePrefix={true}
                      onFilesChange={(newFiles) => {setTargetImage(newFiles[0])}}
                    />
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <td>AR Type</td>
              <td>
                <select value={arType} onChange={(e) => {setARType(e.target.value)}}>
                  <option value="image">Image</option>
                  <option value="face">Face</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>

        <div>
          {submitting && (
            <div>Loading...</div>
          )}
          {!submitting && (
            <button onClick={saveHandler}>Save</button>
          )}
        </div>
      </div>
    </div>
  )
}

export default _;
