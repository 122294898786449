import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import './styles/home.scss';
import './styles/set.scss';
import './styles/card.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AssetsProvider} from './contexts/assets-context';
import {AuthProvider} from './contexts/auth-context';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <AssetsProvider>
        <App />
      </AssetsProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
