import {useState, useCallback} from "react";
import toast from 'react-hot-toast';
import {callAPI} from '../libs/api-helper';
import {useAuth} from '../contexts/auth-context';
import { useNavigate } from "react-router-dom";

const _ = () => {
  const [password, setPassword] = useState("");
  const {signIn} = useAuth();
  const navigate = useNavigate();

  const loginHandler = useCallback(async () => {
    const result = await callAPI('auth/login', {password});
    if (!result.success) {
      toast.error('Login failed');
      return;
    }
    signIn({token: result.token});
    navigate("/");
  }, [password, toast]);

  return (
    <div>
      <h4>Admin Login</h4>
      <input placeholder="password" type="password" value={password} onChange={(e) => {setPassword(e.target.value)}}/>
      <button onClick={loginHandler}>Login</button>
    </div>
  )
}
export default _;
