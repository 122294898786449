import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import {useCallback, useState, useEffect} from "react";
import LoginPage from './pages/login-page';
import HomePage from './pages/home-page';
import SetPage from './pages/set-page';
import CardPage from './pages/card-page';
import { Toaster } from 'react-hot-toast';
import {useAuth} from './contexts/auth-context';

import logo from './logo.svg';
import './App.css';

const PrivateRoute = ({children}) => {
  const {authState} = useAuth();

  if (!authState.token) {
    return <Navigate to="/login" replace />;
  }
  return children;
}

function App() {
  const [initialized, setInitialized] = useState(false);
  const auth = useAuth();

  const init = useCallback(async () => {
    const {token} = await auth.initialize();
    setInitialized(true);
  }, [setInitialized]);

  useEffect(() => {
    init();
  }, []);

  if (!initialized) return (
    <div>Loading...</div>
  );

  return (
    <div className="App">
      <Toaster position="top-right" reverseOrder={true} />

      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<PrivateRoute><HomePage/></PrivateRoute>} /> 
          <Route exact path="/set/:setId" element={<PrivateRoute><SetPage/></PrivateRoute>} />
          <Route exact path="/card/:cardId" element={<PrivateRoute><CardPage/></PrivateRoute>} />
          <Route exact path="/login" element={<LoginPage/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
