export default ({cardList, addressable}) => {
  const {meta, files} = addressable;

  const itemList = cardList.map((card) => {
    const foundInAddressable = meta.effects.indexOf(card._id) !== -1;
    return Object.assign({
      foundInAddressable
    }, card);
  });

  //const metaFile = files.find((f) => f.name.match(/arcademi\.json/));
  //const catalogFile = files.find((f) => f.name.match(/catalog.*\.json/));
  return (
    <div>
      <table border="1" cellSpacing="0">
        <tbody>
          <tr>
            <td width="200">Generated Time</td>
            <td>{meta.generatedTime}</td>
          </tr>

          <tr>
            <td>Card List</td>
            <td>
              <ol>
                {itemList.map((card) => (
                  <li key={card._id}>
                    {card.title}
                    {!card.foundInAddressable && (
                      <span className="warning">Missing Effect!</span>
                    )}
                  </li>
                ))}
              </ol>
            </td>
          </tr>

          <tr>
            <td>File List</td>
            <td>
              <ol>
                {files.map((file, fIndex) => (
                  <li key={fIndex}>
                    <a target="_blank" href={meta.fullPath + "/" + file.name} target="_blank">{file.name}</a>
                  </li>
                ))}
              </ol>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
