import React, { useContext, useCallback, useState } from 'react';

const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    token: undefined
  });

  const signIn = useCallback(({token}) => {
    setAuthState({
      ...authState,
      token,
    });
    localStorage.setItem('token', token);
  }, [authState, setAuthState]);

  const setAuthToken = useCallback((token) => {
    setAuthState({
      ...authState,
      token,
    });
  }, [authState, setAuthState]);

  const signOut = useCallback(() => {
    setAuthState({
      token: undefined,
    });
    localStorage.removeItem('token');
  }, [setAuthState]);

  const initialize = useCallback(() => {
    const token = localStorage.getItem('token');
    setAuthState({
      token: token,
    });
    return {token};
  }, [setAuthState]);

  return (
    <AuthContext.Provider value={{ authState, setAuthToken, signIn, signOut, initialize }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export {
  AuthProvider,
  useAuth
}
