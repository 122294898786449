import { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { callAPI } from "../libs/api-helper";

function SetRow({ set, fetchSets, sets, goSetHandler }) {
  const [setName, setSetName] = useState("");
  const [title, setTitle] = useState("");
  const [group, setGroup] = useState("");
  const [parentId, setParentId] = useState("");
  const [unlockWithoutCode, setUnlockWithoutCode] = useState(false);

  useEffect(() => {
    setSetName(set.setName || "");
    setGroup(set.group || "");
    setParentId(set.parentId || "");
    setTitle(set.title || "");
    setUnlockWithoutCode(set.unlockWithoutCode || false);
  }, [set.setName, set.group, set.parentId, set.title, set.unlockWithoutCode]);

  const updateDisabled = useMemo(() => {
    return (
      set.setName == setName &&
      set.parentId == parentId &&
      set.title == title &&
      set.unlockWithoutCode === unlockWithoutCode &&
      set.group == group
    );
  }, [
    set.setName,
    set.group,
    set.parentId,
    set.title,
    set.unlockWithoutCode,
    setName,
    group,
    parentId,
    title,
    unlockWithoutCode,
  ]);

  const removeSetHandler = useCallback(
    async id => {
      const result = await callAPI("assets/remove-set", { id });
      if (result && result.success) {
        await fetchSets();
        toast.success("removed set successfully");
      } else {
        toast.error("removed set failed");
      }
    },
    [fetchSets]
  );

  const updateSetData = useCallback(async () => {
    const result = await callAPI(`assets/set-update/${set._id}`, {
      setName,
      group,
      parentId,
      title,
      unlockWithoutCode,
    });
    if (result && result.success) {
      await fetchSets();
      toast.success("set updated successfully");
    } else {
      toast.error("set updated failed");
    }
  }, [fetchSets, setName, group, parentId, title, unlockWithoutCode]);

  const copySet = useCallback(async () => {
    const result = await callAPI(`assets/set-copy/${set._id}`);
    if (result && result.success) {
      await fetchSets();
      toast.success("set copied successfully");
    } else {
      toast.error("set copying failed");
    }
  }, []);

  const publishSetHandler = useCallback(
    async id => {
      const result = await callAPI("assets/publish-set", { id });
      if (result) {
        await fetchSets();
        toast.success("published set");
      }
    },
    [fetchSets]
  );
  const unpublishSetHandler = useCallback(
    async id => {
      const result = await callAPI("assets/unpublish-set", { id });
      if (result) {
        await fetchSets();
        toast.success("published set");
      }
    },
    [fetchSets]
  );

  return (
    <>
      <td>
        <input
          value={setName}
          onChange={e => {
            setSetName(e.target.value);
          }}
        />
      </td>
      <td>
        <input
          value={title}
          onChange={e => {
            setTitle(e.target.value);
          }}
        />
      </td>
      <td>
        <input
          value={group}
          onChange={e => {
            setGroup(e.target.value);
          }}
        />
        <label>
          <input
            type="checkbox"
            checked={unlockWithoutCode}
            onChange={() => {
              setUnlockWithoutCode(!unlockWithoutCode);
            }}
          />
          Unlock Without Code
        </label>
      </td>

      <td>
        <select
          onChange={e => {
            setParentId(e.target.value);
          }}
          value={parentId}
        >
          <option value={""}>None</option>
          {sets.map(s => {
            return (
              <option
                key={s._id}
                value={s._id}
                // selected={s._id == parentId}
              >
                {s.setName}
              </option>
            );
          })}
        </select>
      </td>
      <td>
        <button
          onClick={e => {
            e.preventDefault();
            updateSetData();
          }}
          disabled={updateDisabled}
        >
          Update
        </button>
        {set.status !== "published" ? (
          <>
            <button
              onClick={e => {
                e.stopPropagation();
                removeSetHandler(set._id);
              }}
            >
              Delete
            </button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button
              onClick={e => {
                e.stopPropagation();
                publishSetHandler(set._id);
              }}
            >
              Publish
            </button>
          </>
        ) : (
          <>
            {set.status === "published" && <span>Published</span>}
            <button
              onClick={e => {
                e.stopPropagation();
                unpublishSetHandler(set._id);
              }}
            >
              Unpublish
            </button>
          </>
        )}
        <button
          onClick={copySet}
        >
          Copy Set
        </button>
        <span>---</span>
        <button
          onClick={e => {
            e.preventDefault();
            goSetHandler(set._id);
          }}
        >
          Open Set
        </button>
      </td>
    </>
  );
}

export { SetRow };
