import React, {useState, useCallback, useMemo, useContext} from 'react';

const APP_API_URL = process.env.REACT_APP_API_URL;

const AssetsContext = React.createContext();

const AssetsProvider = ({children}) => {
  const value = {
  }
  return <AssetsContext.Provider value={value}>{children}</AssetsContext.Provider>
}

const useAssets = () => useContext(AssetsContext);

export {
  AssetsProvider,
  useAssets,
}
