export const readJsonFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const obj = JSON.parse(event.target.result);
      resolve(obj);
    };
    reader.readAsText(file);
  });
}

export const formatSize = (size) => {
  return (size/1024/1024).toFixed(2) + "MB";
}
