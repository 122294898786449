const APP_API_URL = process.env.REACT_APP_API_URL;

export const callAPI = async (path, params, method) => {
  const token = localStorage.getItem('token');
  const fullpath = APP_API_URL + "/api/" + path;

  const response = await fetch(fullpath, {
    method: method || "POST",
    headers: { 
      "Content-Type": "application/json",
      "authorization": token,
    },
    body: JSON.stringify(params)
  });
  const responseJSON = await response.json();
  return responseJSON;
}

export const uploadFile = async (file, uploadPath) => {
  const token = localStorage.getItem('token');
  const result = await callAPI("assets/generate-presigned-url", {uploadPath});
  if (!result.success) return false;

  const url = result.url;

  const response = await fetch(url, {
    method: 'PUT',
    body: file,
    headers: {'x-amz-acl': 'public-read'},
  });

  if (!response.ok) return false;

  const result2 = await callAPI("assets/verify-asset", {uploadPath});
  if (!result2.success) {
    return false;
  }

  return {
    path: result2.path,
    size: result2.size
  }
}
