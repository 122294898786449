import toast from 'react-hot-toast';
import {useMemo, useState, useCallback, useEffect, React} from "react";
import { useNavigate } from "react-router-dom";
import {callAPI} from '../libs/api-helper';
import {formatSize} from '../libs/file-helper';
import classnames from 'classnames';
import UnityAssetsUploader from '../components/unity-assets-uploader';
import FileInput from '../components/file-input';
import {useAuth} from '../contexts/auth-context';
import { SetRow } from '../components/set-row';

const _ = () => {
  const navigate = useNavigate();
  const {signOut} = useAuth();
  const [selectedTab, setSelectedTab] = useState("sets");
  const [sets, setSets] = useState([]);
  const [addressables, setAddressables] = useState([]);
  const [newTitle, setNewTitle] = useState("");
  const [newName, setNewName] = useState("");
  const [newUnityAssetName, setNewUnityAssetName] = useState("");

  const [themeButtonDownloadSet, setThemeButtonDownloadSet] = useState(null);
  const [themeButtonInstruction, setThemeButtonInstruction] = useState(null);
  const [themeButtonHomepage, setThemeButtonHomepage] = useState(null);
  const [themeBackgroundImage, setThemeBackgroundImage] = useState(null);

  const fetchGlobalSettings = useCallback(async () => {
    const result = await callAPI('assets/get-global-settings', {});
    if (!result.item) return;
    if (!result.item.theme) return;

    if (result.item.theme.buttonDownloadSet) {
      setThemeButtonDownloadSet(result.item.theme.buttonDownloadSet);
    }
    if (result.item.theme.buttonInstruction) {
      setThemeButtonInstruction(result.item.theme.buttonInstruction);
    }
    if (result.item.theme.buttonHomepage) {
      setThemeButtonHomepage(result.item.theme.buttonHomepage);
    }
    if (result.item.theme.backgroundImage) {
      setThemeBackgroundImage(result.item.theme.backgroundImage);
    }

  }, [setThemeButtonDownloadSet, setThemeButtonInstruction, setThemeButtonHomepage, setThemeBackgroundImage]);

  const fetchAddressables = useCallback(async () => {
    const result = await callAPI('assets/addressables', {});
    setAddressables(result.list);
    console.log("address", result.list);
  }, [setAddressables]);

  const fetchSets = useCallback(async () => {
    const result = await callAPI('assets/sets', {});
    console.log("set list", result);
    setSets(result.list);
  }, [setSets]);

  const goSetHandler = useCallback((setId) => {
    navigate("/set/" + setId);
  }, [navigate]);

  const removeAddressableHandler = useCallback(async (id) => {
    const result = await callAPI('assets/remove-addressable', {id});
    if (result && result.success) {
      await fetchAddressables();
      toast.success('removed addressable successfully');
    } else {
      toast.error('removed addressable failed');
    }
  }, [fetchAddressables]);

  const newTitleChangeHandler = useCallback((e) => {
    setNewTitle(e.target.value);
  }, [setNewTitle]);

  const newNameChangeHandler = useCallback((e) => {
    setNewName(e.target.value);
  }, [setNewName]);

  const newUnityAssetNameHandler = useCallback((e) => {
    setNewUnityAssetName(e.target.value);
  }, [setNewUnityAssetName]);

  const logoutHandler = useCallback(async () => {
    signOut();
  }, [signOut]);

  const createSetHandler = useCallback(async () => {
    if (!newTitle) {
      console.log("missing code");
      toast.error("missing title.");
      return;
    }
    if (!newName) {
      console.log("missing title");
      toast.error("missing name.");
      return;
    }
    const result = await callAPI('assets/create-set', {title: newTitle, setName: newName});
    if (result) {
      await fetchSets();
      toast.success('created set');
    }
  }, [newTitle, newName, fetchSets]);

  const updateGlobalTheme = useCallback(async () => {
    const theme = {
      buttonDownloadSet: themeButtonDownloadSet,
      buttonInstruction: themeButtonInstruction,
      buttonHomepage: themeButtonHomepage,
      backgroundImage: themeBackgroundImage,
    }
    const updateResult = await callAPI("assets/update-global-settings", {theme});

    if (updateResult.success) {
      toast.success('update theme successful');
    } else {
      toast.error("update theme failed");
    }
  }, [themeButtonDownloadSet, themeButtonInstruction, themeButtonHomepage, themeBackgroundImage]);

  const addressableList = useMemo(() => {
    return addressables.map((add) => {
      const set = sets.find((s) => {
        if (s._id === add.meta.setId) return true;

        // legacy: meta without setId
        if (s.unityAddressableIdIOS === add._id || s.unityAddressableIdAndroid === add._id) return true;

        return false;
      });
      const isActive = set && (set.unityAddressableIdIOS === add._id || set.unityAddressableIdAndroid === add._id)

      const catalogFile = add.files.find((f) => f.name.match(/catalog.*\.json/));
      const catalogPath = add.meta.fullPath + "/" + catalogFile.name;

      return Object.assign(add, {set: set, catalogPath, isActive});
    });
  }, [addressables, sets]);

  useEffect(() => {
    fetchSets();
    fetchAddressables();
    fetchGlobalSettings();
  }, [fetchSets, fetchAddressables, fetchGlobalSettings]);

  return (
    <div className="home-page">
      <div className="common-header">
        <div className="left">
          <div>Arcademi CMS</div>
        </div>
        <div className="right">
          <span className="link" onClick={logoutHandler}>Logout</span>
        </div>
      </div>

      <div className="main">
        <div className="common-tabs-header">
          <div className={classnames("tab", {selected: selectedTab==='sets'})} onClick={() => setSelectedTab("sets")}>
            Card Sets
          </div>

          <div className={classnames("tab", {selected: selectedTab==='theme'})} onClick={() => setSelectedTab("theme")}>
            Theme
          </div>

          <div className={classnames("tab", {selected: selectedTab==='unity-addressables'})} onClick={() => setSelectedTab("unity-addressables")}>
            Unity Addressables
          </div>
        </div>

    	<div className={classnames("common-tabs-content", {selected: selectedTab==="theme"})}>
        <div className="theme-settings">
          <table>
            <tbody>
              <tr>
                <td>Download Set Button Image</td>
                <td>
                  {themeButtonDownloadSet && (
                    <div>
                      <img alt="target" width="200px" src={themeButtonDownloadSet.path} />
                      <div>
                        <button onClick={() => {setThemeButtonDownloadSet(null)}}>Clear</button>
                      </div>
                    </div>
                  )}

                  {!themeButtonDownloadSet && (
                    <FileInput 
                      allowMultiple={false}
                      format={['png', 'jpg', 'jpeg']}
                      prefix={"global-theme"}
                      generateUniqueFilePrefix={true}
                      onFilesChange={(newFiles) => {setThemeButtonDownloadSet(newFiles[0])}}
                    />
                  )}
                </td>
              </tr>

              <tr>
                <td>Instruction Button Image</td>
                <td>
                  {themeButtonInstruction && (
                    <div>
                      <img alt="target" width="200px" src={themeButtonInstruction.path} />
                      <div>
                        <button onClick={() => {setThemeButtonInstruction(null)}}>Clear</button>
                      </div>
                    </div>
                  )}

                  {!themeButtonInstruction && (
                    <FileInput 
                      allowMultiple={false}
                      format={['png', 'jpg', 'jpeg']}
                      prefix={"global-theme"}
                      generateUniqueFilePrefix={true}
                      onFilesChange={(newFiles) => {setThemeButtonInstruction(newFiles[0])}}
                    />
                  )}
                </td>
              </tr>

              <tr>
                <td>HomePage Button Image</td>
                <td>
                  {themeButtonHomepage && (
                    <div>
                      <img alt="target" width="200px" src={themeButtonHomepage.path} />
                      <div>
                        <button onClick={() => {setThemeButtonHomepage(null)}}>Clear</button>
                      </div>
                    </div>
                  )}

                  {!themeButtonHomepage && (
                    <FileInput 
                      allowMultiple={false}
                      format={['png', 'jpg', 'jpeg']}
                      prefix={"global-theme"}
                      generateUniqueFilePrefix={true}
                      onFilesChange={(newFiles) => {setThemeButtonHomepage(newFiles[0])}}
                    />
                  )}
                </td>
              </tr>

              <tr>
                <td>Background Image</td>
                <td>
                  {themeBackgroundImage && (
                    <div>
                      <img alt="target" width="200px" src={themeBackgroundImage.path} />
                      <div>
                        <button onClick={() => {setThemeBackgroundImage(null)}}>Clear</button>
                      </div>
                    </div>
                  )}

                  {!themeBackgroundImage && (
                    <FileInput 
                      allowMultiple={false}
                      format={['png', 'jpg', 'jpeg']}
                      prefix={"global-theme"}
                      generateUniqueFilePrefix={true}
                      onFilesChange={(newFiles) => {setThemeBackgroundImage(newFiles[0])}}
                    />
                  )}
                </td>
              </tr>

              <tr>
                <td></td>
                <td>
                  <button onClick={updateGlobalTheme}>Update</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    	<div className={classnames("common-tabs-content", {selected:selectedTab==="sets"})}>
	  <div className="title">List of Card Sets</div>
	  <div className="set-list">
	    <table className='common-table'>
	      <thead>
		<tr>
		  <th>Name</th>
		  <th>Access Key</th>
		  <th>Group</th>
		  <th>Parent</th>
		  <th>Actions</th>
		</tr>
	      </thead>
	      <tbody>
		{sets.map((set, index) => (
		  <tr className="selectable" key={index}>
		    <SetRow set={set} fetchSets={fetchSets} sets={sets} goSetHandler={goSetHandler} />
		  </tr>
		))}
	      </tbody>
	    </table>

      <div className="footnote">
      * For safety reason, published set cannot be deleted in the CMS. It require unpublishing manually in database. 
      </div>
	  </div>

	  <div className="new-set-form">
      <input placeholder="Set Name" value={newName} onChange={newNameChangeHandler}/>
	    <input placeholder="Set Access Key" value={newTitle} onChange={newTitleChangeHandler}/>
	    <button onClick={createSetHandler}>Create Set</button>
	  </div>

	</div>

    	<div className={classnames("common-tabs-content", {selected:selectedTab==="unity-addressables"})}>
	    <div className="unity-assets-list">
	      <div className="title">List of Addressables</div>
	      <table className='common-table'>
		<thead>
		  <tr>
		    <th>Set</th>
		    <th>Platform</th>
		    <th>Path</th>
		    <th># Effects</th>
		    <th>Size</th>
		    <th>CreatedAt</th>
		    <th></th>
		  </tr>
		</thead>
		<tbody>
		  {addressableList.map((addressable, index) => (
		    <tr className="selectable" key={index}>
		      <td>
            {addressable.set && addressable.set.title} {addressable.isActive? "(Active)": ""}
          </td>
		      <td>{addressable.meta.buildTarget}</td>
          <td>
            <a target="_blank" href={addressable.catalogPath}>{addressable.meta.path.split("/").pop()}</a>
          </td>
		      <td>{addressable.meta.effects.length}</td>
		      <td>
		    	{formatSize(addressable.files.reduce((a, f) => a + f.size, 0))}
		      </td>
		      <td>{addressable.lastUpdated}</td>
          <td>
            {!addressable.isActive && (
              <button onClick={() => removeAddressableHandler(addressable._id)}>Delete</button>
            )}
          </td>
		    </tr>
		  ))}
		</tbody>
	      </table>
	    </div>
	</div>
      </div>
    </div>
  )
}

export default _;
