import toast from 'react-hot-toast';
import {useState, useCallback, useEffect} from 'react';
import {useDropzone} from 'react-dropzone'
import {callAPI, uploadFile} from '../libs/api-helper';
const { ObjectID } = require('bson');

export default ({allowMultiple, format, prefix, onFilesChange, generateUniqueFilePrefix}) => {
  const [processing, setProcessing] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const onDrop = useCallback(async (files) => {
    setProcessing("uploading...");

    const uploadedFiles = [];

    for (let i = 0; i < files.length; i++) {
      setProcessing(`uploading... (${i+1} / ${files.length})`);

      const file = files[i];
      const ext = file.name.split(".").pop().toLowerCase();

      if (format && !format.includes(ext)) {
	toast.error('incorrect file format: ' + ext);
	return;
      }

      let filename = file.name;
      if (generateUniqueFilePrefix) {
	filename = (new ObjectID().toString()) + "-" + filename;
      }
      const uploadPath = prefix + "/" + filename;

      const v = await uploadFile(file, uploadPath);
      if (!v) {
	toast.error('upload failed: ' + file.name);
	return;
      }

      uploadedFiles.push({
	path: v.path,
	size: v.size
      });
    }
    console.log("setuploadedFiles", uploadedFiles);
    setUploadedFiles(uploadedFiles);
    setProcessing(null);

  }, [setProcessing, setUploadedFiles])

  const {getRootProps, getInputProps} = useDropzone({
    multiple: allowMultiple,
    onDrop: onDrop 
  });

  useEffect(() => {
    if (onFilesChange) 
      onFilesChange(uploadedFiles);
  }, [uploadedFiles]);

  return (
    <div>
      {uploadedFiles.length === 0 && (
        <div className="dropzone" {...getRootProps()}>
          <input {...getInputProps()} />

          <div className="title">Drop file here, or click to select</div>
	  {format &&
	    <div className="format">
	      <div>{format.join(", ")}</div>
	    </div>
	  }
        </div>
      )}

      {processing && (
        <div>{processing}</div>
      )}
    </div>
  )
}
