import { useEffect, useState, useCallback} from 'react';
import UnityAssetsInput from './unity-assets-input';
import { useParams } from 'react-router-dom';
import {callAPI} from '../libs/api-helper';
import Addressable from './addressable';

export default () => {
  const [showUnityAssetCreate, setShowUnityAssetCreate] = useState(false);
  const [iOSAddressable, setIOSAddressable] = useState(null);
  const [androidAddressable, setAndroidAddressable] = useState(null);
  const [cardList, setCardList] = useState([]);

  const {setId} = useParams();

  const fetchAddressables = useCallback(async () => {
    const result = await callAPI('assets/set', {id: setId});

    const cardsResult = await callAPI('assets/cards', {setId: setId});
    setCardList(cardsResult.list);

    if (result.item.unityAddressableIdIOS) {
      const iOSResult = await callAPI('assets/addressable', {id: result.item.unityAddressableIdIOS});
      console.log("iOSResult", iOSResult);
      if (iOSResult.success) {
        setIOSAddressable(iOSResult.item);
      }
    }
    if (result.item.unityAddressableIdAndroid) {
      const androidResult = await callAPI('assets/addressable', {id: result.item.unityAddressableIdAndroid});
      if (androidResult.success) {
        setAndroidAddressable(androidResult.item);
      }
    }
  }, [setId, setCardList]);

  const onAssetsUploaded = useCallback(() => {
    setShowUnityAssetCreate(false);
    fetchAddressables();
  }, [setShowUnityAssetCreate, fetchAddressables]);

  useEffect(() => {
    fetchAddressables();
  }, [fetchAddressables]);

  return (
    <div>
      <div className="header">
        <div className="right">
          {!showUnityAssetCreate && (
            <button onClick={() => setShowUnityAssetCreate(true)}>Upload New Addressable...</button>
          )}
          {showUnityAssetCreate && (
            <button onClick={() => setShowUnityAssetCreate(false)}>Cancel</button>
          )}
        </div>
      </div>

      <div>
        {showUnityAssetCreate && (
          <UnityAssetsInput onAssetsUploaded={onAssetsUploaded}/>
        )}

        {!showUnityAssetCreate && (
          <div>
            <h3>iOS Addressable</h3>

            {!iOSAddressable && (
              <div className="warning">Missing!</div>
            )}

            {iOSAddressable && (
              <Addressable addressable={iOSAddressable} cardList={cardList} />
            )}

            <h3>Android Addressable</h3>

            {!androidAddressable && (
              <div className="warning">Missing!</div>
            )}

            {androidAddressable && (
              <Addressable addressable={androidAddressable} cardList={cardList} />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
