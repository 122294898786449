import toast from 'react-hot-toast';
import {useState, useCallback, useEffect, React} from "react";
import classnames from 'classnames';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import {callAPI} from '../libs/api-helper';
import FileInput from '../components/file-input';
import UnityAssets from '../components/unity-assets';

const APP_API_URL = process.env.REACT_APP_API_URL;

const _ = () => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("cards");
  const {setId} = useParams();
  const [set, setSet] = useState(null);
  const [unityCatalogURLAndroid, setUnityCatalogURLAndroid] = useState("");
  const [unityCatalogURLIOS, setUnityCatalogURLIOS] = useState("");
  const [cards, setCards] = useState([]);
  const [addressables, setAddressables] = useState([]);
  const [androidAddressableId, setAndroidAddressableId] = useState("");
  const [iOSAddressableId, setIOSAddressableId] = useState("");

  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [backgroundColor1, setBackgroundColor1] = useState("");
  const [foregroundColor1, setForegroundColor1] = useState("");
  const [backgroundColor2, setBackgroundColor2] = useState("");
  const [foregroundColor2, setForegroundColor2] = useState("");
  const [backgroundColor3, setBackgroundColor3] = useState("");

  const fetchAddressables = useCallback(async () => {
    const result = await callAPI('assets/addressables', {});
    setAddressables(result.list);
    console.log("address", result.list);
  }, [setAddressables]);

  const refetchSet = useCallback(async () => {
    const result = await callAPI('assets/set', {id: setId});
    console.log("result", result);
    setUnityCatalogURLAndroid(result.item.unityCatalogURLAndroid || "");
    setUnityCatalogURLIOS(result.item.unityCatalogURLIOS || "");
    setAndroidAddressableId(result.item.unityAddressableIdAndroid || "");
    setIOSAddressableId(result.item.unityAddressableIdIOS || "");
    setSet(result.item);

    if (result.item.theme) {
      setThumbnailImage(result.item.theme.thumbnailImage);
      setBackgroundImage(result.item.theme.backgroundImage);
      setBackgroundColor1(result.item.theme.backgroundColor1);
      setForegroundColor1(result.item.theme.foregroundColor1);
      setBackgroundColor2(result.item.theme.backgroundColor2);
      setForegroundColor2(result.item.theme.foregroundColor2);
      setBackgroundColor3(result.item.theme.backgroundColor3);
    }

  }, [setThumbnailImage, setBackgroundImage, setBackgroundColor1, setBackgroundColor2, setForegroundColor1, setForegroundColor2, setBackgroundColor3, setAndroidAddressableId, setIOSAddressableId, setUnityCatalogURLIOS, setUnityCatalogURLAndroid, setSet, setId]);

  const refetchCards = useCallback(async () => {
    const result = await callAPI('assets/cards', {setId});
    console.log("card list", result);
    setCards(result.list);
  }, [setCards, setId]);

  const updateSettings = useCallback(async () => {
    const updateParams = {id: setId};

    updateParams.unityCatalogURLAndroid = unityCatalogURLAndroid;
    updateParams.unityCatalogURLIOS = unityCatalogURLIOS;
    updateParams.unityAddressableIdAndroid = "";
    updateParams.unityAddressableIdIOS = "";

    const updateResult = await callAPI("assets/update-set", updateParams);

    if (updateResult.success) {
      toast.success('update successful');
    } else {
      toast.error("update failed");
    }

  }, [setId, unityCatalogURLAndroid, unityCatalogURLIOS]);

  const updateTheme = useCallback(async () => {
    const theme = {
      thumbnailImage,
      backgroundImage,
      backgroundColor1,
      foregroundColor1,
      backgroundColor2,
      foregroundColor2,
      backgroundColor3,
    }
    console.log("update theme", theme);
    const updateResult = await callAPI("assets/update-set-theme", {id: setId, theme});

    if (updateResult.success) {
      toast.success('update theme successful');
    } else {
      toast.error("update theme failed");
    }

  }, [setId, thumbnailImage, backgroundImage, backgroundColor1, foregroundColor1, backgroundColor2, foregroundColor2, backgroundColor3]);

  const updateSettingsNew = useCallback(async () => {
    const updateParams = {
      id: setId,
      unityAddressableIdAndroid: androidAddressableId,
      unityAddressableIdIOS: iOSAddressableId
    };

    if (androidAddressableId) {
      const add = addressables.find((add) => add._id === androidAddressableId);
      if (add) {
	const catalogFile = add.files.find((f) => f.name.match(/catalog.*\.json/));
	updateParams.unityCatalogURLAndroid = add.meta.fullPath + "/" + catalogFile.name; 
      }
    } else {
      updateParams.unityCatalogURLAndroid = ""; 
    }

    if (iOSAddressableId) {
      const add = addressables.find((add) => add._id === iOSAddressableId);
      if (add) {
	const catalogFile = add.files.find((f) => f.name.match(/catalog.*\.json/));
	updateParams.unityCatalogURLIOS = add.meta.fullPath + "/" + catalogFile.name; 
      }
    } else {
      updateParams.unityCatalogURLIOS = ""; 
    }

    const updateResult = await callAPI("assets/update-set", updateParams);

    if (updateResult.success) {
      toast.success('update successful');
    } else {
      toast.error("update failed");
    }

  }, [setId, addressables, androidAddressableId, iOSAddressableId]);

  const createCardHandler = useCallback(async () => {
    const result = await callAPI('assets/create-card', {setId});
    if (result) {
      await refetchCards();
      toast.success('created card');
    }
  }, [setId, refetchCards]);
  
  const goCardHandler = useCallback((cardId) => {
    navigate("/card/" + cardId);
  }, [navigate]);
  
  const removeCardHandler = useCallback(async (cardId) => {
    const result = await callAPI('assets/remove-card', {id: cardId});
    if (result && result.success) {
      await refetchCards();
      toast.success('removed card');
    } else {
      toast.error('removed card failed');
    }
  }, [refetchCards]);

  const backHandler = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    refetchSet();
    refetchCards();
    fetchAddressables();
  }, [fetchAddressables, refetchSet, refetchCards]);


  return (
    <div className="set-page">
      <div className="common-header">
        <div>
          <div className="link" onClick={backHandler}>Back</div>
        </div>
      </div>

      <div className="main">

    	<div className="common-tabs-header">
          <div className={classnames("tab", {selected: selectedTab==='cards'})} onClick={() => setSelectedTab("cards")}>
    	    Cards
	  </div>
          <div className={classnames("tab", {selected: selectedTab==='theme'})} onClick={() => setSelectedTab("theme")}>
    	    Theme
	  </div>
          <div className={classnames("tab", {selected: selectedTab==='assets'})} onClick={() => setSelectedTab("assets")}>
          Unity Assets
	  </div>
    	</div>

    	<div className={classnames("common-tabs-content", {selected:selectedTab==="cards"})}>
	  <div className="title">{set && set.title} - Card List</div>
	  <div className="card-list">
	    <table className='common-table'>
	      <thead>
		<tr>
		  <th width={200}>Index</th>
		  <th>Title</th>
		  <th>AR Type</th>
		  <th></th>
		</tr>
	      </thead>
	      <tbody>
		{cards.map((card, index) => (
		  <tr className="selectable" key={index} onClick={() => goCardHandler(card._id)}>
		    <td>
          {index + 1}
          {!card.targetImage && (
            <span className="warning">Missing Target Image!</span>
          )}
        </td>
		    <td>{card.title}</td>
		    <td>{card.arType}</td>
		    <td>
          {set && set.status !== 'published' && (
            <button onClick={(e) => {e.stopPropagation(); removeCardHandler(card._id);}}>Delete</button>
          )}
		    </td>
		  </tr>
		))}
	      </tbody>
	    </table>
	  </div>

	  <div className="new-card-form">
	    <button onClick={createCardHandler}>Create Card</button>
	  </div>
	</div>

    	<div className={classnames("common-tabs-content", {selected:selectedTab==="theme"})}>
        <div className="theme-settings">
          <table>
            <tbody>
              <tr>
                <td>Thumbnail Image</td>
                <td>
                  {thumbnailImage && (
                    <div>
                      <img alt="target" width="200px" src={thumbnailImage.path} />
                      <div>
                        <button onClick={() => {setThumbnailImage(null)}}>Clear</button>
                      </div>
                    </div>
                  )}

                  {!thumbnailImage && (
                    <FileInput 
                      allowMultiple={false}
                      format={['png', 'jpg', 'jpeg']}
                      prefix={"sets/" + setId + "/theme"}
                      generateUniqueFilePrefix={true}
                      onFilesChange={(newFiles) => {setThumbnailImage(newFiles[0])}}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>Background Image</td>
                <td>
                  {backgroundImage && (
                    <div>
                      <img alt="target" width="200px" src={backgroundImage.path} />
                      <div>
                        <button onClick={() => {setBackgroundImage(null)}}>Clear</button>
                      </div>
                    </div>
                  )}

                  {!backgroundImage && (
                    <FileInput 
                      allowMultiple={false}
                      format={['png', 'jpg', 'jpeg']}
                      prefix={"sets/" + setId + "/theme"}
                      generateUniqueFilePrefix={true}
                      onFilesChange={(newFiles) => {setBackgroundImage(newFiles[0])}}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  Submit Button Color
                </td>
                <td>
                  #<input type="text" value={backgroundColor1} onChange={(e) => setBackgroundColor1(e.target.value)}/> (hex)
                </td>
              </tr>
              <tr>
                <td>
                  Submit Text Color
                </td>
                <td>
                  #<input type="text" value={foregroundColor1} onChange={(e) => setForegroundColor1(e.target.value)}/> (hex)
                </td>
              </tr>
              <tr>
                <td>
                  Try Another Button Color
                </td>
                <td>
                  #<input type="text" value={backgroundColor2} onChange={(e) => setBackgroundColor2(e.target.value)}/> (hex)
                </td>
              </tr>
              <tr>
                <td>
                Try Another Text Color
                </td>
                <td>
                  #<input type="text" value={foregroundColor2} onChange={(e) => setForegroundColor2(e.target.value)}/> (hex)
                </td>
              </tr>
              <tr>
                <td>
                  Correct Answer Box
                </td>
                <td>
                  #<input type="text" value={backgroundColor3} onChange={(e) => setBackgroundColor3(e.target.value)}/> (hex)
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <button onClick={updateTheme}>Update</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>

    	<div className={classnames("common-tabs-content", {selected:selectedTab==="assets"})}>
        <div className="assets-settings">
		      <UnityAssets/>
        </div>
      </div>
   
      </div>
    </div>
  )
}

export default _;
