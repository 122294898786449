import toast from 'react-hot-toast';
import {useState, useMemo, useCallback, useEffect} from 'react';
import {useDropzone} from 'react-dropzone'
import {callAPI, uploadFile} from '../libs/api-helper';
import {readJsonFile} from '../libs/file-helper';
import { useParams } from 'react-router-dom';

export default ({onAssetsUploaded}) => {
  const [processing, setProcessing] = useState(null);
  const [meta, setMeta] = useState(null);
  const [files, setFiles] = useState([]);
  const [cardList, setCardList] = useState([]);
  const {setId} = useParams();

  console.log("onAssetsUploaded", onAssetsUploaded);

  const onDrop = useCallback(async (files) => {
    const metaFile = files.find((f) => f.name.match(/arcademi\.json/));
    const catalogFile = files.find((f) => f.name.match(/catalog.*\.json/));
    if (!metaFile || !catalogFile) {
      toast.error('Invalid folder. Missing meta file or catalog file');
      return;
    }

    const metaData = await readJsonFile(metaFile);
    console.log("meta data", metaData);
    console.log("catalog file", catalogFile.name);
    console.log("files", files);

    if (metaData.setId !== setId) {
      toast.error('Incorrect set. Your addressable is built for a different card set.');
      return;
    }

    Object.assign(metaData, {generatedTime: catalogFile.name.substring(8, catalogFile.name.length-5)});

    const setResult = await callAPI('assets/set', {id: setId});
    console.log("set resut", setResult);

    const cardsResult = await callAPI('assets/cards', {setId: setId});

    const cardList = cardsResult.list.map((card) => {
      const foundInAddressable = metaData.effects.indexOf(card._id) !== -1;
      return Object.assign({
        foundInAddressable
      }, card);
    });
    console.log("cardList", cardList);
    setCardList(cardList);

    setMeta(metaData);
    setFiles(files);
  }, [setMeta, setFiles, setCardList])

  const uploadHandler = useCallback(async () => {
    console.log("upload...");

    setProcessing("uploading...");
    for (let i = 0; i < files.length; i++) {
      setProcessing(`uploading... (${i+1} / ${files.length})`);

      const file = files[i];

      const uploadPath = meta.path + "/" + file.name;
      const v = await uploadFile(file, uploadPath);

      if (!v) {
        toast.error('upload failed: ' + file.name);
        return;
      }
    }

    const existingAddressableResult = await callAPI('assets/addressable', {path: meta.path});
    let addressableId;
    if (existingAddressableResult && existingAddressableResult.item !== null) { // normally, shouldn't happen. unless the previous upload has error
      addressableId = existingAddressableResult.item._id;
    } 
    else {
      const createResult = await callAPI('assets/create-addressable', {
        title: "--",
        meta, 
        files: files.map((f) => {
          return {
            name: f.name,
            size: f.size,
          }
        })
      });

      if (!createResult.success) {
        toast.error('failed to create addressable');
        return;
      }
      addressableId = createResult.newAddressable._id;
    }

    const catalogFile = files.find((f) => f.name.match(/catalog.*\.json/));
    const updateParams = {
      id: setId,
    };
    if (meta.buildTarget === 'iOS') {
      updateParams.unityCatalogURLIOS = meta.fullPath + "/" + catalogFile.name;
      updateParams.unityAddressableIdIOS = addressableId;
    } 
    else if (meta.buildTarget === 'Android') {
      updateParams.unityCatalogURLAndroid = meta.fullPath + "/" + catalogFile.name;
      updateParams.unityAddressableIdAndroid = addressableId;
    }

    const updateResult = await callAPI("assets/update-set", updateParams);
    if (!updateResult) {
      toast.error('failed to update set addressable');
      return;
    }

    await callAPI("assets/fix-card-addressable-keys", {setId: setId});

    toast.success('upload successful');

    onAssetsUploaded();

    setProcessing("uploaded");
  }, [meta, files, setProcessing, onAssetsUploaded]);

  const {getRootProps, getInputProps} = useDropzone({
    multiple: true,
    onDrop: onDrop 
  });

  return (
    <div>
      {!meta && (
        <div className="dropzone" {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="title">Drop the whole iOS or Android folder here</div>
        </div>
      )}

      {meta && (
	<div>
	  <table border="1">
	    <tbody>
	      <tr>
          <td>Generate Time</td>
          <td>{meta.generatedTime}</td>
	      </tr>

	      <tr>
          <td>Platform</td>
          <td>{meta.buildTarget}</td>
	      </tr>

	      <tr>
          <td>Upload Path</td>
          <td>{meta.fullPath}</td>
	      </tr>

        <tr>
          <td>Card List</td>
          <td>
            <ol>
              {cardList.map((card) => (
                <li key={card._id}>
                  {card.title}
                  {!card.foundInAddressable && (
                    <span className="warning">Missing Effect!</span>
                  )}
                </li>
              ))}
            </ol>
          </td>
        </tr>

	      <tr>
          <td>File List</td>
          <td>
            <ol>
              {files.map((file, fIndex) => (
                <li key={fIndex}>{file.name}</li>
              ))}
            </ol>
          </td>
	      </tr>

	      <tr>
          <td></td>
          <td>
            {!processing && (
              <button onClick={uploadHandler}>Upload</button>
            )}

            {processing && (
              <div>{processing}</div>
            )}
          </td>
	      </tr>
	    </tbody>
	  </table>
	</div>
      )}

    </div>
  )
}
